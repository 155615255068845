.error-page {
    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
        color: $errorPageColor3;
        font-family: Arial, Helvetica, sans-serif;
        text-decoration: none;
    }

    #navbar {
        position: relative;
        z-index: 1000;
        background: none;
    }

    @include keyframes(rain) {
        from {
            background-position: 0 0;
        }

        to {
            background-position: -400em 1000em, -450em 1050em;
        }
    }

    @include keyframes(cloud1) {
        from {
            background-position: 0 top;
        }

        to {
            background-position: -1024px top;
        }
    }

    @include keyframes(cloud2) {
        from {
            background-position: -600px top;
        }

        to {
            background-position: -1624px top;
        }
    }

    @include keyframes(sign404) {
        0% {
            transform: rotate(0deg) translate(20px);
        }

        20% {
            transform: rotate(-4deg) translate(-20px);
        }

        30% {
            transform: rotate(0deg) translate(20px);
        }

        100% {
            transform: rotate(0deg) translate(20px);
        }
    }

    @include keyframes(light) {
        0% {
            background-color: $errorPageColor1;
        }

        10% {
            background-color: $errorPageColor1;
        }

        11% {
            background-color: $errorPageColor2;
        }

        12% {
            background-color: $errorPageColor1;
        }

        30% {
            background-color: $errorPageColor1;
        }

        31% {
            background-color: $gray;
        }

        32% {
            background-color: $errorPageColor1;
        }

        100% {
            background-color: $errorPageColor1;
        }
    }

    #sign {
        animation-name: sign404;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        position: absolute;
        bottom: 0;
        right: 10%;
        display: block;
        height: 500px;
        width: 300px;
        background: url("../images/error/sign.png") no-repeat center top;
    }

    #rain {
        animation-name: rain;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        background-image: url("../images/error/raindrops2.png"), url("../images/error/raindrops3.png");
    }

    #clouds1 {
        animation-name: cloud1;
        animation-duration: 40s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        position: absolute;
        display: block;
        width: 100%;
        height: 300px;
        background: url("../images/error/clouds2.png") repeat-x;
        top: 0;
    }

    #clouds2 {
        animation-name: cloud2;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        position: absolute;
        width: 100%;
        height: 600px;
        background: url("../images/error/clouds1.png") repeat-x -600px top;
        top: 0;
    }

    footer {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        bottom: 0;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        color: $errorPageColor3;
    }
}
