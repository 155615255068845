$backgroundColor: #191919;
$bodyColor: #ecf0f1;
$blue: #37709c;
$blueIconColor: #ecf0f1;
$blueIconHover: #2c3e50;
$blackIconHover: #2c3e50;
$blackIconBorder: #fdfa74;
$white: #fff;
$black: #000;
$grey: #000;
$gray: #000;
$link: #464646;
$boxBorder1: #85caf3;
$boxBorder2: #85caf3;
$boxBorder3: #85caf3;
$boxBorder4: #85caf3;
$boxBorder5: #3a000b;
$roundButtonBackground: #3a000b;
$roundButtonBackgroundHover: #262626;
$footerHeight: 70px;
$desktopWidth: 1025px;
$tabletWidth: 768px;
$mobileWidth: 320px;

$errorPageColor1: #243442;
$errorPageColor2: #666;
$errorPageColor3: #ffc300;
