html {
    position: relative;
    min-height: 100%;
}

body {
    background: $backgroundColor;
    color: $bodyColor;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: $footerHeight;

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
        color: $link;
        text-decoration: none;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footerHeight;
    font-size: 12px;
    color: $link;
}

.input-block-level {
    min-height: 100px;
}

#icons {
    margin-left: 10px;
}

#icons ul li a {
    border: 1px solid $backgroundColor;
    text-decoration: none;
    font-size: 24px;
    margin-top: 14px;
    display: inline-block;
    zoom: 1;
    width: 40px;
    text-align: center;
    padding: 5px;
}

#icons ul li {
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
}

.box-container {
    max-width: 800px;
}

.box {
    width: 230px;
    height: 230px;
    display: block;
    background: $backgroundColor;
    margin: 1em auto;
    border-radius: 100%;
    border: 3px solid $boxBorder1;
}

.box img {
    display: block;
    width: 220px;
    height: 220px;
    margin: 2px auto;
    border-radius: 100%;
    border-style: none;
}

.sa .box img {
    width: 220px;
    height: 220px;
    border: 0;
}

.shuffle-box {
    width: 60px;
    height: 60px;
    border: 3px solid $backgroundColor;
    background-color: $roundButtonBackground;
    margin: 6em auto;

    &:hover {
        background: $roundButtonBackgroundHover;
        border: 3px solid $boxBorder5;
        text-decoration: none;
    }
}

.shuffle-box img {
    display: block;
    width: 30px;
    height: 30px;
    margin: 10px auto;
    border-radius: 100%;
    border-style: none;
}

.round-button {
    display: block;
    margin: 75px auto;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border: 3px solid $boxBorder5;
    border-radius: 100%;
    color: $white;
    text-align: center;
    text-decoration: none;
    background: $roundButtonBackground;
    font-size: 30px;
    font-family: Courier, "Lucida Console", monospace;

    &:hover {
        background: $roundButtonBackgroundHover;
        border: 3px solid $boxBorder5;
        text-decoration: none;
        color: $white;
    }
}

.art-box {
    border: 3px solid $boxBorder2;
}

.photography-box {
    border: 3px solid $boxBorder3;
}

.maintext {
    width: 100%;
    display: block;
    overflow: auto;
    margin: 0 auto 0;
}

.maintext p {
    width: 100%;
    margin: 0.2em 0;
    clear: both;
    font-family: Courier, "Lucida Console", monospace;
}

.maintext p span.content {
    float: left;
}

@media only screen and (min-width: $desktopWidth) {
    .maintext {
        margin: 10% auto 0;
    }
}
//@media only screen and (min-width: $mobileWidth) {
//    .maintext {
//        width: 100%;
//        overflow: auto;
//        margin: 10% auto 0;
//    }
//}
//
//@media only screen and (min-width: $tabletWidth) {
//    .maintext {
//        width: 50%;
//        overflow: auto;
//        margin: 5% auto 0;
//    }
//}
//
//@media only screen and (min-width: $desktopWidth) {
//    .maintext {
//        width: 50%;
//        overflow: auto;
//        margin: 5% auto 0;
//    }
//}

@include keyframes(shadow) {
    10% {
        box-shadow: 0 2px 30px $black;
    }

    20% {
        box-shadow: 0 2px 30px $black;
    }

    30% {
        box-shadow: 0 2px 30px $black;
    }

    40% {
        box-shadow: 0 2px 30px $black;
    }

    90% {
        box-shadow: 0 2px 30px $white;
    }
}

#insert-point {
    position: relative;
    display: none;
    height: 18px;
    width: 2px;
    background: $white;
    animation: fader 1s linear infinite;
    float: left;
}

#page {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    padding: 10px 0;
}

#hidden {
    font-size: 10px;
    color: #333;
    text-indent: -9999px;
}
